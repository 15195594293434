.call-requests{
    position: relative;
    width: 95%;
    height: 90%;
    overflow: auto;
    top: 0%;
    left: 2.5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
}

.call-requests::-webkit-scrollbar{
    display: none;
}

.call-request{
    background-image: linear-gradient(to left, skyblue, #4930B4);
    width: 98%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    border-radius: 10px;
    padding: 15px 20px;
}

.call-request > div{
    width: calc(100% - 60px);
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}