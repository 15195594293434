.awards-certificates{
    width: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 100px;
    margin: auto;
    justify-content: space-evenly;
    gap: 20px;
    position: relative;
}

.awards-certificates > h1{
    position: absolute;
    top: 0px;
    color: #12478E;
    background-color: white;
    padding: 10px 30px;
    border-radius: 15px;
    transition: .2s;
    user-select: none;
    cursor: pointer;
    font-size: 18pt;
}

.awards-certificates > h1:hover{
    color: transparent;
    background-image: linear-gradient(orange, red);
    background-clip: text;
}

.awards-certificates > .ac-item{
    width: 100%;
    max-width: 550px;
    background-image: linear-gradient(to right, #12478E, #407BB1, #12478E);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 5px 5px 15px 5px #4783B7;
    cursor: pointer;
    user-select: none;
    transition: .5s;
    position: relative;
    height: 400px;
}

.awards-certificates > .ac-item:hover{
    scale: 1.02;
}

.awards-certificates > .ac-item:active{
    scale: 1;
}

.awards-certificates > .ac-item > h1{
    color: white;
    padding: 10px 10px 0px 10px;
    font-size: 18pt;
}

.awards-certificates > .ac-item > p{
    color: white;
    padding: 10px;
    text-align: justify;
    height: 75px;
    font-size: 10pt;
}

.awards-certificates > .ac-item > img{
    display: block;
    width: 100%;
    height: 'auto';
}