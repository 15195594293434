.projects-body-main{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 350px);
}

/***********************************************/

.projects-body-main > .projects-body-header{
    position: relative;
    width: 100%;
    height: 80px;
}

.projects-body-main > .projects-body-header > ul{
    display: flex;
    position: absolute;
    left: 50px;
    overflow: hidden;
    border-radius: 50px;
}

.projects-body-main > .projects-body-header > ul > li{
    list-style: none;
    width: 150px;
    text-align: center;
    background-color: #1D4387;
    color: white;
    padding: 8px 15px;
    font-size: 15pt;
    cursor: pointer;
    user-select: none;
    transition: .2s;
}

.projects-body-main > .projects-body-header > ul > li:hover{
    background-color: #ff8b56;
    color: #1D4387;
}

/***********************************************/

.projects-body-main > .projects-sub-body{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding-left: 15px;
}

/***********************************************/

.projects-body-main > .projects-sub-body > .projects-sub-body-view{
    display: flex;
    flex-direction: row;
    width: calc(100% - 360px);
    justify-content: left;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0px 20px;
    overflow: auto;
    height: calc(100vh - 450px);
}

.psbv{
    height: auto !important;
    margin: auto !important;
    max-width: 1000px !important;
    justify-content: start;
    padding-top: 10px !important;
}

.projects-body-main > .projects-sub-body > .projects-sub-body-view::-webkit-scrollbar{
    display: none;
}

/***********************************************/

.projects-body-main > .projects-sub-body > .projects-sub-body-navigation{
    position: relative;
    width: 350px;
}

.projects-body-main > .projects-sub-body > .projects-sub-body-navigation > ul{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 10px;
    overflow: hidden;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
}

.projects-body-main > .projects-sub-body > .projects-sub-body-navigation > ul > li{
    list-style: none;
    width: 300px;
    text-align: left;
    background-color: #1D4387;
    color: white;
    padding: 8px 20px 8px 30px;
    font-size: 16pt;
    cursor: pointer;
    user-select: none;
    transition: .2s;
}

.projects-body-main > .projects-sub-body > .projects-sub-body-navigation > ul > li:hover{
    background-color: #ff8b56;
    color: #1D4387;
}

/**********************************************/

@media screen and (max-width: 768px) {
    .projects-body-main{
        padding: 0px 0px 300px 0px;
    }
    .projects-body-main > .projects-body-header > ul{
        left: 50%;
        transform: translate(-50%, 0);
    }
    .projects-body-main > .projects-body-header > ul > li{
        width: fit-content;
        min-width: 100px;
    }
    .projects-body-main > .projects-sub-body{
        flex-direction: column-reverse;
    }
    .projects-body-main > .projects-sub-body > .projects-sub-body-view{
        width: 100%;
        position: relative;
        overflow: visible;
        height: auto;
        justify-content: center;
        top: 260px;
    }
    .projects-body-main > .projects-sub-body > .projects-sub-body-navigation{
        width: 100%;
        position: relative;
    }
}