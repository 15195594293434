* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
}

html,body{
    background-image: url('./images/nbg2.jpg');
    background-position: center middle;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

.header-main-app>div {
    padding: 0px !important;
    background-color: white !important;
}

.header-main-app>div>div {
    min-height: 80px !important;
    /* background-color: transparent !important; */
    /* backdrop-filter: none !important; */
}

@keyframes gradientAnimation {
    from {
        background-position: 0% 0%;
    }

    to {
        background-position: 0% 100%;
    }
}

.active>p {
    color: #F26522 !important;
}

.header-nav-p-parent:hover {
    background: none !important;
}

.header-nav-p {
    transition: .2s !important;
}

.header-nav-p:hover {
    color: #F26522 !important;
}

.proBox {
    max-width: 100% !important;
}

.vidBox {
    position: relative;
}

.vid {
    position: relative;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0px);
    height: 100%;
}

.projects-card {
    cursor: pointer;
}

.projects-card-media {
    transition: .2s;
}

.projects-card-media:hover {
    transform: scale(1.05);
}

@media screen and (max-width:791px) {
    .footer-main {
        height: 140px !important;
    }

    .header-main-app>div>div {
        min-height: 64px !important;
    }

    .projects-header {
        top: 64px;
    }

    .drawer-items>.active {
        color: #F26522 !important;
    }

    .our-top-projects {
        font-size: 14pt !important;
    }

    .our-top-projects:hover {
        font-size: 16pt !important;
    }

    .top-projects-popup {
        width: 90% !important;
        height: 400px !important;
    }
}

.gif{
    display: block;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100vw !important;
    object-fit: fill;
}

.gif > video{
    display: block;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100vw !important;
    height: 100%;
    object-fit: fill;
}

.projects-header {
    width: 100%;
    height: 250px;
    background-color: black;
    /* background-image: url('./images/projects-image.jpg'); */
    background-position: center 60%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin: 0px !important;

}



.news-header {
    width: 100%;
    height: 250px;
    background-color: black;
    /* background-image: url('./images/news-image.jpg'); */
    background-position: center 60%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    margin: 0px !important;

}

.top-projects-section {
    padding-bottom: 30px;
}

.top-projects-card {
    width: 500px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
}

.top-projects-card>img {
    transition: .2s;
    cursor: pointer;
}

.top-projects-card>p {
    position: absolute;
    left: 10px;
    bottom: 10px;
    background-color: #093F88;
    color: white;
    font-size: 14pt;
    padding: 10px 30px;
    border-radius: 5px;
    cursor: default;
    user-select: none;
}

.top-projects-card>img:hover {
    transform: scale(1.1);
}


.our-top-projects-div {
    height: 100px;
    background-color: #093F88;
    position: relative;
    overflow: hidden;
}

.our-top-projects {
    transition: .2s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30pt;
}

.our-top-projects:hover {
    text-shadow: 0px 0px 5px rgba(255, 119, 0, 0.993);
    /* transform: scale(1.05); */
    font-size: 40pt;
}

.browse-projects-div {
    width: 100%;
    height: 70px;
    position: relative;
}

.browse-projects-div>div {
    border: none;
    background-color: #093F88;
    color: white;
    text-decoration: none;
    padding: 10px 30px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    width: 300px;
    height: 55px;
    overflow: hidden;
}

.browse-projects-div>div>a {
    display: block;
    color: white;
    text-decoration: none;
    position: relative;
    font-size: 16pt;
    transition: .2s;
    text-align: center;
}

.browse-projects-div>div>a:hover {
    font-size: 18pt;
    font-weight: bold;
}

.top-projects-popup {
    width: 800px;
    height: 600px;
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    background-color: white;
    overflow: hidden;
    transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
    visibility: hidden;
    box-shadow: 0px 0px 10px -5px black;
    z-index: 10001;
    border-radius: 5px;
}

.top-projects-popup.active {
    transform: translate(-50%, -50%) scale(1);
    visibility: visible;
}


/* =================================================== */


.services-container {
    max-width: 1200px;
    padding: 20px;
    padding-top: 50px;
    /* background-color: white; */
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    margin-bottom: 50px;
}

.services-section {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.services-section h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
}

.services-section p {
    color: #666;
    font-size: 16px;
    margin-bottom: 50px;
    text-align: left;
}

.services-grid {
    background-color: #ffffff;
    padding: 100px 100px 100px 100px;
    border-radius: 10px;
    transition: .2s;
    box-shadow: 0px 0px 40px -5px #093F88 inset;
}

.services-grid>.div1,
.services-grid>.div2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0px;
    margin: auto;
}

.services-grid>.div1 {
    max-width: 900px;
}

.services-grid>.div2 {
    max-width: 600px;
}

.service-item {
    flex: 1 1 220px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: .2s;
}

.services-grid>div>.service-item:hover {
    scale: 1.05;
}

.services-grid>div>.service-item:hover>.si-1 {
    box-shadow: 0px 0px 20px -5px #093F88;
}

.service-item>.si-1 {
    display: flex;
    position: relative;
    transform: rotate(-45deg);
    width: 155px;
    height: 155px;
    background-color: transparent;
    cursor: pointer;
    transform-origin: center;
    box-shadow: 0px 0px 20px -10px #093F88;
    border-radius: 10px;
    transition: .2s;
    z-index: 1;
    overflow: hidden;
}

.service-item>.si-1>span {
    display: inline-block;
    position: absolute;
    height: 150px;
    width: 150px;
    z-index: -1;
    /* background-image: conic-gradient(#093F88, orange); */
    background-color: #F26522;
    animation-name: spanborder;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* animation-play-state: paused;
    visibility: hidden; */
}

.service-item>.si-1:hover>span {
    visibility: hidden;
    animation-play-state: paused;
}

@keyframes spanborder {
    0% {
        top: -140px;
        left: -140px;
        /* background-color: #F26522; */
    }

    25% {
        top: 145px;
        left: -140px;
        /* background-color: red; */
    }

    50% {
        top: 145px;
        left: 145px;
        /* background-color: #F26522; */
    }

    75% {
        top: -140px;
        left: 145px;
        /* background-color: red; */
    }

    100% {
        top: -140px;
        left: -140px;
        /* background-color: #F26522; */
    }
}

.service-item>.si-1>.si-2 {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #093F88; */
    background-image: linear-gradient(#093F88, skyblue);
    border-radius: 10px;
    padding: 1px;
    z-index: -1;
}

.service-item>.si-1>.si-2>.si-3 {
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    top: 40%;
    left: 60%;
    width: max-content;
    height: fit-content;
    transition: .2s;
    z-index: 1;
}

.service-item .si-1>.si-2>.si-3>img {
    width: 35px;
    height: 35px;
    margin-bottom: 15px;
    cursor: pointer;
}

.service-item>.si-1>.si-2>.si-3>p {
    font-size: 13px;
    color: #FFFFFF;
    text-transform: uppercase;
    margin: 0;
    user-select: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .service-item {
        flex: 1 1 120px;
    }

    .service-item img {
        width: 40px;
        height: 40px;
    }

    .services-section h2 {
        font-size: 20px;
    }

    .services-section p {
        font-size: 14px;
    }
}


/* ============================================= */


.stats-container div {
    text-align: center;
    color: #093F88;
    font-size: 24px;
    /* Adjust based on your design */
}

.stats-container h1 {
    font-size: 40px;
    /* Adjust based on your design */
}

/* ============================================= */

.about-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    line-height: 1.6;
}

.about-container h1 {
    font-size: 28px;
    color: #0a4870;
    /* A professional blue shade */
    margin-bottom: 20px;
}

.about-container h2 {
    font-size: 24px;
    color: #14496b;
    /* A darker shade of blue */
    margin-top: 40px;
    margin-bottom: 10px;
}

.values-section-chairman>h2 {
    margin: 0px 0px 20px 0px !important;
}

.about-container p {
    font-size: 16px;
    margin-bottom: 10px;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.team-member img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.team-member h3 {
    font-size: 18px;
    color: #333;
}

.team-member p {
    font-size: 14px;
    color: #666;
}

.about-text-hover:hover {
    transform: translate(-50%, 0px) scale(1.05) !important;
}

.about-text-hover:active {
    transform: translate(-50%, 0px) scale(1) !important;
}

.about-mission {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    /* background-color: #EFEFEF; */
    margin: 20px 20px;
    transition: .3s;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0px 0px 10px 4px gray inset;
    transform: scale(.5);
    padding: 10px;
}

@media screen and (max-width: 768px) {
    .about-mission {
        width: 350px;
        height: 350px;
        margin: 10px;
    }

}

.about-mission:hover {
    transform: scale(1.02);
    border-radius: 10px;
    border: 2px solid #1256A4;
    box-shadow: 0px 0px 10px -2px black;
    padding: 4px;
    transition: .3s;
}

.about-mission:hover>.inner-mission {
    border-radius: 10px;
}

.about-mission:hover>.inner-mission>h3 {
    display: none;
}

.about-mission:hover>.inner-mission>p {
    display: block;
}

.about-mission:active {
    transform: scale(1);
}

.about-mission:hover>.dynamic-border {
    visibility: hidden;
}

.about-mission:hover > .inner-mission{
    background-image: linear-gradient(skyblue, #093F88);
}

.about-mission:hover > .inner-mission::before{
    display: none;
}

.about-mission>.dynamic-border {
    display: block;
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #F18151;
    z-index: -1;
    animation-name: dynamicborder;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


@keyframes dynamicborder {
    0% {
        top: -100px;
        left: -100px;
        /* background-color: #F26522; */
    }

    75% {
        top: 400px;
        left: -100px;
        /* background-color: red; */
    }

    50% {
        top: 400px;
        left: 400px;
        /* background-color: #F26522; */
    }

    25% {
        top: -100px;
        left: 400px;
        /* background-color: red; */
    }

    100% {
        top: -100px;
        left: -100px;
        /* background-color: #F26522; */
    }
}

@keyframes rotateBackground {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.about-mission>.inner-mission {
    width: 100%;
    height: 100%;
    background-image: conic-gradient(skyblue, #093F88);
    border-radius: 50%;
    transition: .3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inner-mission::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: inherit;
    background-image: inherit;
    animation: rotateBackground 3s linear infinite;
}

.about-mission>.inner-mission>h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(3);
    color: white;
}

.about-mission>.inner-mission>p {
    display: none;
    width: 450px;
    text-align: justify;
    margin-bottom: 10px;
    margin-left: 20px;
    color: white;
}

/* =================================== */

.img-gallery:hover {
    transform: scale(1.1);
}

/* ================================== */

.send-button {
    background-color: white;
    color: #093F88;
    border: 2px solid #093F88;
    transition: .2s;
}

.send-button:hover {
    background-color: #093F88;
    color: white;
    transform: scale(1.1);
    box-shadow: 0px 0px 15px -10px #093F88;
}

input,
textarea {
    background-color: white;
    box-shadow: 0px 0px 15px -10px #093F88;
    transition: .2s;
}

textarea:focus,
input:focus {
    transform: scale(1.05);
    border-color: green !important;
}

textarea {
    resize: vertical;
}

.projects-card-media{
    object-fit: fill !important;
}