*{
    box-sizing: border-box !important;
}

.admin-container > .logo{
    height: 50px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 10;
}

.login-alert{
    position: absolute;
    top: 30px;
    font-size: 20pt;
    background-color: orange;
    color: white;
    padding: 5px 25px;
    border-radius: 50px;
    left: 50%;
    transform: translate(-50%, 0px);
}

.admin-login{
    width: 500px;
    height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #DFDFDF;
    box-shadow: 0px 0px 20px -5px gray inset;
    border-radius: 10px;
    padding: 20px;
}

.admin-login > .login-logo{
    width: 400px;
    height: 100px;
    margin: auto;
    background-image: url('./admin-logo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    overflow: hidden;
    background-color: white;
    border-radius: 5px;
}

.admin-login > form{
    width: 400px;
    height: 250px;
    margin: auto;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.admin-login > form > input{
    width: 100%;
    font-size: 16pt;
    text-align: center;
    padding: 10px 20px;
    outline: none;
    border-radius: 50px;
    border: 1px solid darkblue;
}

.admin-login > form > input:focus{
    border: 1px solid green;
}

.admin-login > form > .login-submit{
    cursor: pointer;
    width: 200px;
    margin-left: 100px;
}

.admin-header-container{
    width: 90%;
    height: 60px;
    background-image: radial-gradient(#7804ed, #1d4387);
    border-radius: 10px;
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admin-header-container > ul{
    display: flex;
    width: 700px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.admin-header-container > ul > li{
    list-style: none;
}

.admin-header-container > ul > li > a{
    display: block;
    color: #CFCFCF;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14pt;
}

.admin-header-container > ul > li > .active{
    color: white !important;
}

.admin-header-container > ul > li:hover > a{
    color: white;
}

.admin-body{
    display: block;
    position: fixed;
    box-sizing: border-box;
    width: 90%;
    top: 130px;
    height: calc(100vh - 140px);
    left: 50%;
    transform: translate(-50%, 0px);
    overflow: hidden;
    box-shadow: 0px 0px 5px 1px gray inset;
    border-radius: 8px;
    padding: 10px;
}

.admin-body::-webkit-scrollbar{
    display: none;
}

.admin-projects > ul{
    width: 400px;
    display: flex;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0px);
    border-radius: 50px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 0px #5A27C8 inset;
    overflow: hidden;
}

.admin-projects > ul > li{
    width: 200px;
    list-style: none;
}

.admin-projects > ul > li > a{
    text-decoration: none;
    display: block;
    font-size: 15pt;
    text-align: center;
    width: 100%;
    color: #1d4387;
    padding: 10px 25px;
    transition: .1s;
}

.admin-projects > ul > li > .active{
    color: white !important;
    background-image: radial-gradient(#1d4387, #7804ed);
}

.admin-projects-body{
    width: 90%;
    height: calc(100% - 100px);
    border-radius: 8px;
    box-shadow: 0px 0px 3px 1px black inset;
    box-sizing: border-box;
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translate(-50%, 0px);
    overflow: auto;
    padding: 10px;
    z-index: 2;
}

.admin-projects-body::-webkit-scrollbar{
    display: none;
}

.add-project{
    z-index: 1 !important;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: row-reverse;
    justify-content: left;
}

.add-project::-webkit-scrollbar{
    display: none;
}

.add-project > img{
    width: 48%;
    height: 500px;
    border-radius: 8px;
}

.add-project > form{
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 400px;
    z-index: -1 !important;
    margin: 10px 2%;
}

.add-project > form > input, .add-project > form > select, .add-project > form > textarea{
    outline: none;
    font-size: 18pt;
    padding: 5px 15px;
    border-radius: 8px;
    border: 1px solid #7804ed;
    text-align: center;
    margin-bottom: 10px;
    z-index: -1 !important;
}

.add-project > form > input:hover,
.add-project > form > select:hover,
.add-project > form > textarea:hover{
    border: 1px solid #1d4387;
}

.add-project > form > input::-webkit-file-upload-button{
    border-radius: 8px;
    border: 1px solid #7804ed;
    text-align: center;
    cursor: pointer;
    background-color: white;
    margin-right: 20px;
}

.add-project > form > input:hover::-webkit-file-upload-button{
    color: white;
    background-image: radial-gradient(#1d4387, #7804ed);
}

.add-project > form > .add-button{
    max-width: 200px;
    margin: auto;
    cursor: pointer;
    transition: .2s;
}

.add-project > form > .add-button:hover{
    color: white;
    background-image: radial-gradient(#1d4387, #7804ed);
}

.add-project > form > .add-button:focus{
    transform: scale(1) !important;
}

.add-project > form > .add-button:active{
    transform: scale(.95) !important;
}

.add-project > form > textarea{
    min-height: 45px;
}

.edit-project{
    width: 100%;
    min-height: 97% !important;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: start;
    overflow: hidden;
}

.edit-project > form{
    width: 600px;
    height: 40px;
    position: relative;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0px);
    display: grid;
    grid-template-areas: 'search search search search button';
}

.edit-project > form > input,
.edit-project > form > div > input{
    padding: 0px 15px;
    font-size: 16pt;
    border-radius: 30px;
    position: relative;
    border: 1px solid #5A27C8;
    outline: none;
}

.edit-project > form > input:first-child{
    grid-area: search;
    margin-right: 20px;
}

.edit-project > form > input:last-child{
    grid-area: button;
    cursor: pointer;
    color: #1d4387;
}

.edit-project > form > input:last-child:hover,
.edit-project > form > div > input:last-child:hover{
    background-image: radial-gradient(#1d4387, #7804ed);
    color: white;
}

.edit-project > form > input:last-child:focus,
.edit-project > form > div > input:last-child:focus{
    transform: scale(1) !important;
}

.edit-project > form > input:last-child:active,
.edit-project > form > div > input:last-child:active{
    transform: scale(.95) !important;
}

.edit-project > .add-project{
    position: absolute !important;
    width: calc(100% - 30px);
    top: 70px;
    left: 0px;
    padding-bottom: 100px;
    height: calc(100% - 75px);
    overflow: auto;
}

.edit-project > .add-project::-webkit-scrollbar{
    display: none;
}

.edit-project > .add-project > form > textarea{
    max-height: 100px;
    min-height: 45px;
}

.edit-project > .add-project > form{
    justify-content: start !important;
}

.edit-project > .add-project > form > input,
.edit-project > .add-project > form > div > input{
    margin-bottom: 10px !important;
}

.edit-project > .add-project > form > div > .submit-edit{
    width: 250px;
    margin: 10px auto;
}

.edit-project > .add-project > form > div > .submit-edit:hover{
    background-image: radial-gradient(#1d4387, #7804ed);
    color: white;
}

.edit-project > .add-project > form > div > .submit-edit:focus{
    transform: scale(1) !important;
}

.edit-project > .add-project > form > div > .submit-edit:active{
    transform: scale(.95) !important;
}

.edit-project > .add-project > form > div > .delete-project{
    cursor: pointer;
    width: 250px;
    margin: 5px auto;
    border: 2px solid red;
    color: red;
    font-weight: bold;
}

.edit-project > .add-project > form > div > .delete-project:hover{
    background-image: radial-gradient(#000000, #ff0404);
    color: white;
}

.edit-project > .add-project > form > div > .delete-project:focus{
    transform: scale(1) !important;
}

.edit-project > .add-project > form > div > .delete-project:active{
    transform: scale(.95) !important;
}

.edit-project > .add-project > form > div > input{
    cursor: pointer;
    font-size: 16pt;
    padding: 5px 10px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #7804ed;
}

.edit-project > .add-project > form > div > input:last-child{
    margin-top: 10px;
}

.errorMessage{
    display: block;
    transition: .2s;
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translate(-50%, 0px);
    width: max-content;
    height: auto;
    padding: 5px 20px;
    background-color: orange;
    color: white;
    box-shadow: 0px 0px 5px -2px black;
    border-radius: 10px;
    font-size: 20pt;
    min-width: 400px;
}

.select-box{
    z-index: 10000000000 !important;
}

.select-box::-webkit-scrollbar{
    display: none;
}

.select-div:hover{
    background-color: rgba(0, 0, 0, .2) !important;
}

.admin-clients{
    /* background-image: url('./admins/main_bg.png'); */
    width: 100%;
    height: 100%;
    overflow: auto;
}

.admin-clients > .cont{
    background-image: url('./admins/main_bg.png');
}

.admin-clients > .cont > .part1{
    height: 600px;
    background-image: radial-gradient(rgba(100, 255, 255, .3), rgba(230, 130, 230, .3));
}

.admin-clients  > .cont > .part2{
    height: 2600px;
    background-image: radial-gradient(rgba(0, 150, 255, .3), rgba(255, 150, 0, .3));
}

.admin-clients  > .cont > .part2 > div{
    position: sticky;
    top: 0px;
    display: flex;
    gap: 20px;
    padding: 20px;
    width: 100%;
    overflow: auto;
}

.admin-clients  > .cont > .part2 > div::-webkit-scrollbar{
    display: none;
}

.admin-clients  > .cont > .part2 > div > section{
    background-color: #1d4387;
    min-width: 400px;
    height: 400px;
    border-radius: 10px;
    color: #CFCFCF;
}

.admin-clients > .cont > .part3{
    height: 600px;
    background-image: radial-gradient(rgba(0, 0, 255, .3), rgba(255, 255, 0, .3));
}

.admin-client > ul > li > a{
    color: transparent;
    background-image: linear-gradient(to right, #6F14E2, darkblue);
    background-clip: text;
    transition: .2s;
    min-width: 150px;
}

.admin-client > ul > li > a.active{
    color: white;
    font-weight: bold;
    background-clip: border-box !important;
    background-image: linear-gradient(to bottom, #6F14E2, darkblue);
}

.add-section-button:hover{
    background-color: #5A27C8;
    color: white;
}

.add-section-button:active{
    scale: .95;
}

button:hover{
    background-image: linear-gradient(darkblue, #6f14e2);
    color: white !important;
    transition: .2s;
}

button:active{
    scale: .95;
}

.delete-section:hover{
    background-image: linear-gradient(darkorange, red);
}

.hideScroll::-webkit-scrollbar{
    display: none;
}