.contact-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1200px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: auto;
    box-shadow: 0px 0px 10px 5px #1256A4 inset;
    border-radius: 10px;
    min-height: calc(100vh - 300px);
}

.contact-inputs,
.contact-address{
    max-width: 550px;
    width: 90%;
    position: relative;
}

.contact-address{
    display: block;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .contact-container{
        justify-content: left;
        padding-left: 20px;
    }
}

.message-sent,
.message-error{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background-image: linear-gradient(to left, darkblue, #1256A4);
    width: max-content;
    height: max-content;
    padding: 40px 80px;
    border-radius: 10px;
    box-shadow: 10px 10px 50px -10px black;
    user-select: none;
}

.message-sent > p,
.message-error > p{
    font-size: 20pt;
    font-weight: bold;
    background-image: linear-gradient(to right, gold, orange, red);
    background-clip: text;
    color: transparent;
}

.message-error{
    top: 200px;
    padding: 10px 50px;
    background-color: #1256A4;
    background-image: none;
}

.message-error > p{
    color: white;
    font-weight: 100;
}