.admin-generals{
    width: 100%;
    height: 100%;
}

.admin-general-navigation{
    width: 200px;
    position: fixed;
    left: 10px;
    height: 100%;
    overflow: auto;
    padding: 20px 0px;
}

.admin-general-navigation::-webkit-scrollbar{
    display: none;
}

.admin-general-navigation > ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.admin-general-navigation > ul > li{
    list-style: none;
}

.admin-general-navigation > ul > li > a{
    display: block;
    width: 100%;
    text-decoration: none;
    color: white;
    font-size: 12pt;
    padding: 5px 10px;
    background-color: #323B9A;
    border-radius: 5px;
    transition: .2s;
}

.admin-general-navigation > ul > li > a:hover{
    background-color: #323BcA;
}

.admin-general-navigation > ul > li > a:active{
    scale: .97;
}

.admin-general-navigation > ul > li > a.active{
    color: #323B9A;
    background-color: #F2622E;
    font-weight: bold;
}

.generals-body::-webkit-scrollbar{
    display: none;
}

.generals-body{
    position: fixed;
    right: 10px;
    width: calc(100% - 230px);
    box-shadow: 0px 0px 5px 3px gray inset;
    overflow: auto;
    height: calc(100% - 20px);
    border-radius: 8px;
}

.admin-topimgs{
    width: 90%;
    margin: auto;
    position: relative;
    left: 0px;
    top: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.admin-topimgs > div{
    position: relative;
    left: 0px;
    width: 100% !important;
}

.admin-topimgs > div > video,
.admin-topimgs > img{
    width: 100% !important;
    height: 500px;
    border-radius: 10px;
    left: 0px;
    object-fit: fill;
}

.admin-topimgs > input{
    font-size: 20pt;
    padding: 5px 15px;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    border: 1px solid #323B9A;
}

.admin-topimgs > input::-webkit-inner-spin-button{
    display: none;
}

.admin-topimgs > input::-webkit-file-upload-button{
    cursor: pointer;
    background-color: white;
    color: #323B9A;
    border-radius: 5px;
    outline: none;
    border: 1px solid #323B9A;
}

.admin-topimgs > button{
    font-size: 16pt;
    padding: 5px 15px;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    border: 1px solid #323B9A;
    width: 200px;
    margin: auto;
    background-color: white;
    color: #323B9A;
    transition: .2s;
}

.admin-topimgs > button:disabled{
    background: transparent !important;
    color: gray !important;
    cursor: not-allowed;
}

.admin-topimgs > button:disabled:active{
    scale: 1;
}

.admin-hometext{
    width: 90%;
    margin: auto;
    position: relative;
    top: 20px;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    justify-content: center;
    overflow: auto;
    height: calc(100% - 40px);
}

.admin-hometext > textarea::-webkit-scrollbar{
    display: none;
}

.admin-hometext > textarea{
    display: block;
    outline: none;
    border: 1px solid #323B9A;
    border-radius: 10px;
    font-size: 14pt;
    padding: 5px 15px;
    resize: none;
}

.admin-hometext > button{
    font-size: 14pt;
    width: 200px;
    background-color: white;
    color: #323B9A;
    border: 1px solid #323B9A;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 10px;
    margin: auto;
}

.admin-hometext > button:disabled{
    background: transparent !important;
    color: gray !important;
    cursor: not-allowed;
}

.admin-hometext > button:disabled:active{
    scale: 1;
}

.admin-count{
    width: 1000px;
    margin: auto;
    position: relative;
    top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.admin-count > input{
    display: block;
    width: 450px;
    padding: 5px 15px;
    font-size: 15pt;
    outline: none;
    border: 1px solid #323B9A;
    border-radius: 10px;
    text-align: center;
}

.admin-count > input::-webkit-inner-spin-button{
    display: none;
}

.admin-count > button{
    width: 300px;
    border: 1px solid #323B9A;
    background-color: white;
    color: #323B9A;
    font-size: 15pt;
    border-radius: 10px;
    outline: none;
    padding: 5px 15px;
    cursor: pointer;
}

.admin-count > button:disabled{
    background: transparent !important;
    color: gray !important;
    cursor: not-allowed;
}

.admin-count > button:disabled:active{
    scale: 1;
}

.admin-clients-home-scetion > ul{
    width: 400px;
    overflow: hidden;
    border-radius: 50px;
    margin: 20px auto;
}

.admin-clients-home-scetion > ul > li{
    list-style: none;
    display: inline-block;
    font-size: 14pt;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #323B9A;
    width: 200px;
    text-align: center;
    color: white;
}

.admin-services-items{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 1000px;
    margin: auto;
    position: relative;
    padding: 20px 0px;
    flex-wrap: wrap;
    justify-content: center;
}

.admin-services-items > div{
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: skyblue;
    padding: 10px;
    border-radius: 10px;
    gap: 10px;
}

.admin-services-items > div > img{
    display: block;
    width: auto;
    height: 120px;
    margin: auto;
}

.admin-services-items > div > input{
    display: block;
    font-size: 12pt;
    outline: none;
    border: 1px solid #323B9A;
    border-radius: 10px;
    padding: 5px 15px;
}

.admin-services-items > div > button{
    font-size: 12pt;
    padding: 5px 15px;
    border: 1px solid #323B9A;
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.admin-visitors-count{
    display: flex;
    padding: 20px;
    flex-direction: column;
    width: 500px;
    margin: auto;
    gap: 20px;
}

.admin-visitors-count > input{
    outline: none;
    font-size: 15pt;
    border: 1px solid #323B9A;
    padding: 5px 15px;
    text-align: center;
    border-radius: 10px;
}

.admin-visitors-count > input::-webkit-inner-spin-button{
    display: none;
}

.admin-visitors-count > button{
    outline: none;
    font-size: 15pt;
    border: 1px solid #323B9A;
    padding: 5px 15px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}